<template>
  <div class="reportsMaint container">


    <!-- ---------------------------------------  Report Main -------------------------------------------------- -->

	<div>
		<label for="loadingMsg" v-if="loading">{{loadingMsg}}</label>
		<scale-loader :loading="loading"></scale-loader>
	</div>
	<p class="text-danger" align="left">{{alert}}</p>
	<p class="font-weight-bold" align="center">Report - {{reportName}}</p>
	<br>

	<div>
		<form>
			<div class="form-group row">
				<label class="col-sm-2 col-form-label font-weight-bold text-right">Name</label>
				<input type="text" class="col-sm-4 form-control" v-model="report.name">
				<label class="col-sm-2 col-form-label font-weight-bold text-right">Menu Order</label>
				<input type="number" class="col-sm-2 form-control" v-model="report.menuOrder">
			</div>

			<div class="form-group row">
				<label class="col-sm-2 col-form-label font-weight-bold text-right">Email Recipients</label>
				<input type="textarea" class="col-sm-8 form-control" v-model="report.emailRecipients">
				<br>
				<label class="col-sm-5 col-form-label font-weight-bold text-right">(Comma seperated emails)</label>
			</div>

			<div class="form-group row">
				<label class="col-sm-2 col-form-label font-weight-bold text-right">Calculate Value?</label>
				<div class="col-sm-3 text-left">
					<select class="form-control" v-model="report.calculateValue">
						<option value="true">True</option>
						<option value="false">False</option>
					</select>
				</div>
				<label class="col-sm-2 col-form-label font-weight-bold text-right">Exclude Item2?</label>
				<div class="col-sm-3 text-left">
					<select class="form-control" v-model="report.applyItem2Exclusion">
						<option value="true">True</option>
						<option value="false">False</option>
					</select>
				</div>
			</div>

		</form>
		<br>
		<div class="font-weight-bold" align="center">
			<CButton color="dark" class="mr-3" v-on:click.prevent="serverCall('saveReportTemplate')">Save</CButton>
		</div>

	</div>

  </div>
</template>

<script>
import axios from "axios"
import ScaleLoader from "@/views/utils/ScaleLoader"
import { firebaseAuth } from '../../firebaseConfig.js';
export default {
	name: 'customReportsCrud',

    components: {
        ScaleLoader,
	},
		
	data () {
		return {
			orgId: null,
			alert: '',

            loading: false,
            loadingMsg: null,

			reportName: null,
			report: {},
		}
	},

	computed: {
	},

	mounted(){
		this.orgId = this.$route.params['orgId']
		this.reportName = this.$route.params['reportName']
		this.serverCall('getReportTemplate')
	},

	methods: {
        serverCall(name) {
            var self = this
			firebaseAuth.onAuthStateChanged(user => {
				if (user) {
					user.getIdToken(true).then(idToken => {
						switch(name) {
							case "getReportTemplate":
								return self.getReportTemplate(idToken)
								break;

							case "saveReportTemplate":
								self.saveReportTemplate(idToken)
								break;

							default:
								console.log("Unknown function: " + name)
						}
					})
				}
				else {
					self.alert = "Please login first."
				}
			})
		},

        // Fetch Report Template
        getReportTemplate(authToken) {
            var self = this
			self.alert = null

			// Set loading message
			self.loading = true
			self.loadingMsg = "Fetching Report Template..."

            var formData = new FormData()
			formData.append('reportName', self.reportName)
			console.log(self.reportName)

			axios({
					method: 'POST',
					baseURL: process.env.VUE_APP_ROOT_API,
					url: '/GetReportTemplate/',
					headers: {
						'Authorization': 'Bearer ' + authToken,
						'OrgId': self.orgId,
						'Content-Type': 'multipart/form-data',
					},
					data: formData,
				})
				.then(function (response) {
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
					
					if (response.data.err != ''){
						self.orgAlert = response.data.err
					}
					else {
						console.log(response.data.report)
						self.report = response.data.report
					}
				})
				.catch(function (error) {
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
					
					self.alert = String(error)
				})

		},

		// Save Org Details
        saveReportTemplate(authToken) {
            var self = this
			this.alert = ""

			// Set loading message
			self.loading = true
			self.loadingMsg = "Saving Report Template..."
			
			var formData = new FormData()
			formData.append('report', JSON.stringify(self.report))

			axios({
					method: 'POST',
					baseURL: process.env.VUE_APP_ROOT_API,
					url: '/SaveReportTemplate/',
					data: formData,
					headers: {
						'Authorization': 'Bearer ' + authToken,
						'OrgId': self.orgId,
						'Content-Type': 'multipart/form-data'
					}
				})
				.then(function (response) {
					// Reset loading message
					self.loading = false
					self.loadingMsg = null

					if (response.data.err != '') {
						self.alert = response.data.err
					}
					else {
						self.alert = "Report Template Saved."
					}
				})
				.catch(function (error) {
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
					self.alert = error
				})

		},

	},

}
</script>

